<template>
  <div
    class="flex flex-col h-screen bg-background max-w-md mx-auto overflow-hidden"
  >
    <template v-if="route.name !== 'error-page'">
      <NavBar />
    </template>
    <Notification />
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script setup>
import NavBar from "@/components/NavBar.vue";
import Notification from "@/components/Notification.vue";
import { useRoute } from "vue-router";

const route = useRoute();
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 5px;
  background-color: #111;
}

::-webkit-scrollbar-thumb {
  background: #60cdff;
}
</style>
