import axios from "axios";
import i18n from "@/i18n";
import { AuthConfig, Connect, Customization } from "@/types/api.interface";

export default {
  connect,
  getAssets,
  getCustomizations,
  getSelectedCustomizations,
  saveCustomizations,
};

// TODO: to remove this section after build process is fixed with env
if (
  ["dev", "test"].indexOf(
    window.location.hostname.split(".").at(-1) as string
  ) !== -1
) {
  axios.defaults.baseURL = process.env.VUE_APP_DEV_API_URL;
} else {
  axios.defaults.baseURL = process.env.VUE_APP_API_URL;
}

async function connect({ signature, message, address }: Connect) {
  try {
    const formData = new FormData();
    formData.append("signature", signature);
    formData.append("address", address);
    formData.append("message", message);

    const res = await axios({
      url: "/login",
      method: "post",
      data: formData,
    });

    return res.data;
  } catch (err) {
    throw i18n.global.t("error.signature");
  }
}

async function getAssets({ message, address }: AuthConfig) {
  try {
    const query = new URLSearchParams({
      address,
      message,
    });

    const res = await axios({
      url: `/assets?${query.toString()}`,
    });

    return res.data;
  } catch (err) {
    throw i18n.global.t("error.notfound.assets");
  }
}

async function getCustomizations({ message, address, assetId }: Customization) {
  const query = new URLSearchParams({
    address,
    message,
  });

  const res = await axios({
    url: `/assets/${assetId}/customizations?${query.toString()}`,
  });

  return res.data;
}

async function getSelectedCustomizations({
  message,
  address,
  assetId,
  badgerId,
}: Customization) {
  const query = new URLSearchParams({
    address,
    message,
  });

  const res = await axios({
    url: `/assets/${assetId}/selected-customizations/${badgerId}?${query.toString()}`,
  });

  return res.data;
}

async function saveCustomizations({
  message,
  address,
  assetId,
  assetIndex,
  customizations,
}: Customization) {
  try {
    const formData = new FormData();

    for (let i = 0; i < customizations.length; i++) {
      formData.append("customizationIds[]", customizations[i]);
    }

    const query = new URLSearchParams({
      address,
      message,
    });

    await axios({
      url: `/assets/${assetId}/customizations/${assetIndex}?${query.toString()}`,
      method: "post",
      data: formData,
    });

    return;
  } catch {
    throw i18n.global.t("error.save");
  }
}
