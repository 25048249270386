import { createApp } from "vue";
import App from "@/App.vue";

import VueClickAway from "vue3-click-away";
import VueSocialSharing from "vue-social-sharing";
import { IonIcon } from "@ionic/vue";
import i18n from "@/i18n";

import store from "@/store";
import router from "@/router";

import { initAuthGuard } from "@/helpers/auth.guard";

import "@/styles/index.css";
import "@/styles/animations.css";

initAuthGuard(store, router);

const app = createApp(App);

app.use(i18n);

app.use(store);

app.use(router);

app.use(VueSocialSharing);

app.use(VueClickAway);

app.component("ion-icon", IonIcon);

app.mount("#app");
