export interface State {
  address: string;
  authMessage: string;
  assets: Asset[];
  edit: Edit;
  customizations: Customization[];
  customizationsAssetId: string | null;
  notification: Notification;
  loading: number;
}

export interface Notification {
  type: NotificationType;
  text: string;
}

export interface Edit {
  state: boolean;
  loading: boolean;
  badgerId: string | null;
  assetId: string | null;
  customizations: { [key: string]: Customization | null };
}

export interface Asset {
  id: string | number;
  imageUrl: string;
  type: string;
  tokenId: string;
  assetId: string;
  tokenIndex: number;
  assetIndex: string;
}

export interface Customization {
  id: number;
  assetCustomizationId?: number;
  imageUrl: string;
  imageThumbnailUrl: string;
  type: CustomizationType;
  tokenId?: string;
  assetIndex?: string;
}

export enum CustomizationType {
  hat = "hat",
  shirt = "shirt",
  pants = "pants",
  accessory = "accessory",
  background = "background",
}

export enum NotificationType {
  empty = 0,
  success,
  error,
  warning,
}
