import { ethers } from "ethers";

const { ethereum } = window;

export default { connectInjected, signMessage };

async function connectInjected(): Promise<{
  address: string;
  message: string;
  signature: string;
}> {
  let account = null;
  if (!ethereum) throw "No web3 injection detected!";
  try {
    account = await checkIfConnected();
    if (!account) {
      account = await requestAccess();
    }
  } catch (err) {
    throw "Account request refused.";
  }
  try {
    const { message, signature } = await signMessage();
    return { address: account, message, signature };
  } catch {
    throw "Signature failure.";
  }
}

async function checkIfConnected(): Promise<string | null> {
  const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
  const accounts = await provider.send("eth_accounts", []);
  if (accounts.length !== 0) {
    return accounts[0];
  } else {
    return null;
  }
}

async function requestAccess(): Promise<string> {
  const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
  const accounts = await provider.send("eth_requestAccounts", []);
  return accounts[0];
}

async function signMessage(): Promise<{ message: string; signature: string }> {
  const timestamp = new Date().getTime();

  const dataHash = ethers.utils.id(timestamp + "");
  const dataHashBin = ethers.utils.hexlify(dataHash);

  const provider = new ethers.providers.Web3Provider(ethereum);
  const signer = provider.getSigner();
  const signature = await signer.signMessage(dataHashBin);

  return { message: dataHashBin, signature: signature };
}
