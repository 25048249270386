import walletAddress from "vuex-persistedstate";
import walletMessage from "vuex-persistedstate";

const persistedAddress = walletAddress({
  key: "address",
  paths: ["address"],
});

const persistedMessage = walletMessage({
  key: "authMessage",
  paths: ["authMessage"],
});

export default {
  address: persistedAddress,
  message: persistedMessage,
};
