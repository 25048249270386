import i18n from "@/i18n";
import { Store } from "vuex";
import { NotificationType, State } from "@/types/types.interface";
import { Router } from "vue-router";

export default function (error: any, store: Store<State>, router: Router) {
  const { response } = error;
  switch (response.status) {
    case 401:
    case 403:
      store.dispatch("setNotification", {
        msg: i18n.global.t("notification.expired"),
        error: NotificationType.error,
      });
      store.dispatch("disconnect");
      router.push({ name: "landing" });
      break;
    case 422:
      store.dispatch("setNotification", {
        msg: response.data.message,
        error: NotificationType.error,
      });
      store.dispatch("disconnect");
      router.push({
        name: "error-page",
        params: { from: "api", code: "404" },
      });
      break;
    case 500:
      store.dispatch("setNotification", {
        msg: response.data.message || response.statusText,
        error: NotificationType.error,
      });
      router.push({
        name: "error-page",
        params: { from: "api", code: "500" },
      });
      break;
    default:
      store.dispatch("setNotification", {
        msg: response.data.message || response.statusText || response.error,
        error: NotificationType.error,
      });
  }
  return Promise.reject(error);
}
