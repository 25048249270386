import axios from "axios";
import { Router } from "vue-router";
import { Store } from "vuex";
import { State } from "@/types/types.interface";
import failureInterceptor from "./failure-interceptor";

export function initAuthGuard(store: Store<State>, router: Router) {
  axios.interceptors.response.use(undefined, (error) =>
    failureInterceptor(error, store, router)
  );

  router.beforeEach((to, _, next) => {
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    const address = store.getters.address;
    const params = to.params;

    if (requiresAuth && !address) next({ name: "landing" });
    else {
      if (params.notification_msg) {
        store.dispatch("setNotification", {
          msg: params.notification_msg,
          error: parseInt(params.notification_error as string),
        });
      }

      next();
    }
  });
}
