import { createI18n } from "vue-i18n";

const messages = {
  en: {
    text: {
      landing: {
        title: "Build a Badger Hub",
        text: "Express yourself by customizing NFT Express yourself by customizing NFT Express yourself by customizing NFT Express yourself by customizing NFT",
      },
      address: "Wallet Address",
      backgrounds: "Backgrounds",
      accessories: "Accessories",
      connecting: "Connecting",
    },
    btn: {
      connect: "Connect",
      disconnect: "Sign out",
      save: "Save changes",
      cancel: "Cancel",
      share: "Share",
      home: "Home",
      customize: "Customize",
      reload: "Reload",
    },
    notification: {
      saved: "Saved successfully!",
      connected: "Connected!",
      disconnected: "Disconnected!",
      expired: "Token expired, connect again..",
      unauthorized: "Session expired.",
      network: "Network error",
      link_copy_success: "Badger link copied to clipboard",
      link_copy_fail: "Failed to copy badger link",
      address_copy_success: "Address copied to clipboard",
      address_copy_fail: "Failed to copy the address",
    },
    error: {
      notfound: {
        page: "Page not found.",
        badger: "Badger doesn't exist.",
        badgers: "No badgers was found in this account.",
        assets: "No Azure assets was found.",
        items: "No items found under this section",
      },
      signature: "Invalid signature.",
      save: "Error saving customizations",
      error404: "Error 404",
      error500: "Error 500",
      header404: "Page not found",
      header500: "Server error",
      text404:
        "Something went wrong. Can not find the page you were looking for. Try again later or contact us",
      text500: "Something went wrong. Try again later or contact us",
    },
  },
};

const i18n = createI18n({
  legacy: false,
  locale: "en",
  messages,
});

export default i18n;
