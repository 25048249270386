import { createStore } from "vuex";
import api from "@/services/api-service";
import web3 from "@/services/web3-service";
import persistent from "@/store/persistent";
import i18n from "@/i18n";
import {
  Customization,
  State,
  Asset,
  CustomizationType,
  NotificationType,
} from "@/types/types.interface";

export default createStore<State>({
  state: {
    address: "",
    authMessage: "",
    assets: [],
    edit: {
      state: false,
      loading: false,
      badgerId: null,
      assetId: null,
      customizations: {
        background: null,
        hat: null,
        shirt: null,
        pants: null,
        accessory: null,
      },
    },
    customizations: [],
    customizationsAssetId: null,
    notification: {
      type: 0,
      text: "",
    },
    loading: 0,
  },
  getters: {
    address: (state) => state.address,
    authMessage: (state) => state.authMessage,
    editing: (state) => state.edit.state,
    assets: (state) => state.assets,
    customizations: (state) => state.customizations,
    customizationsByType: (state) => (type: CustomizationType) => {
      return state.customizations.filter(
        (c) =>
          (type !== CustomizationType.background &&
            c.type !== CustomizationType.background) ||
          (type === CustomizationType.background &&
            c.type === CustomizationType.background)
      );
    },
    editCustomizations: (state) => state.edit.customizations,
    selectedAsset:
      (state) =>
      (id: string): Asset | null =>
        state.assets.find((a) => a.id == id) ?? null,
    notification: (state) => state.notification,
    loading: (state) => state.loading,
    selectedCombination: (state) => {
      const { background, hat, shirt, pants, accessory } =
        state.edit.customizations;
      return createLink({ background, hat, shirt, pants, accessory });
    },
  },
  mutations: {
    setAddress(state, address: string) {
      state.address = address;
    },
    setAuthMessage(state, authMessage: string) {
      state.authMessage = authMessage;
    },
    setEditing(state, editState: boolean) {
      state.edit.state = editState;
    },
    setAssets(state, assets: Asset[]) {
      state.assets = assets;
    },
    setCustomizations(state, { customizations, assetId }) {
      state.customizations = customizations;
      state.customizationsAssetId = assetId;
    },
    setCustomization(state, accessory: Customization) {
      if (
        accessory.type !== CustomizationType.background &&
        state.edit.customizations[accessory.type]?.assetCustomizationId ===
          accessory.id
      )
        state.edit.customizations[accessory.type] = null;
      else
        state.edit.customizations[accessory.type] = {
          ...accessory,
          assetCustomizationId: accessory.id,
        };
    },
    setInitialSelectedCustomizations(
      state,
      { customizations, badgerId, assetId }
    ) {
      state.edit.badgerId = badgerId;
      state.edit.assetId = assetId;
      state.edit.customizations.hat =
        customizations.find(
          (c: Customization) => c.type === CustomizationType.hat
        ) || null;
      state.edit.customizations.shirt =
        customizations.find(
          (c: Customization) => c.type === CustomizationType.shirt
        ) || null;
      state.edit.customizations.pants =
        customizations.find(
          (c: Customization) => c.type === CustomizationType.pants
        ) || null;
      state.edit.customizations.accessory =
        customizations.find(
          (c: Customization) => c.type === CustomizationType.accessory
        ) || null;
      state.edit.customizations.background =
        customizations.find(
          (c: Customization) => c.type === CustomizationType.background
        ) || null;
    },
    setNotification(state, data) {
      if (data.msg && state.notification.type !== NotificationType.empty)
        return;
      state.notification.type = data.error;
      state.notification.text = data.msg;
    },
    updateLoader(state, loading = 1) {
      state.loading += loading;
    },
    updateEditLoading(state) {
      state.edit.loading = !state.edit.loading;
    },
    setDefaultBackground(state) {
      state.edit.customizations.background =
        state.customizations.find(
          (a) =>
            a.type === CustomizationType.background &&
            a.imageUrl.includes("default")
        ) ?? null;
    },
    clearEdit(state) {
      state.edit = {
        state: false,
        loading: false,
        badgerId: null,
        assetId: null,
        customizations: {
          hat: null,
          shirt: null,
          pants: null,
          background: null,
          accessory: null,
        },
      };
    },
    resetCustomizations(state, customizations) {
      state.edit.customizations = customizations;
    },
    updateBadgerLink(state, data) {
      state.assets.map((a) => {
        if (a.assetId === data.assetId && a.assetIndex === data.assetIndex)
          a.imageUrl = data.badgerLink;

        return a;
      });
    },
  },
  actions: {
    async connect({ dispatch }, token) {
      try {
        const { message, address, signature } = parseToken(token);
        await dispatch("apiConnect", { address, signature, message });
      } catch (err) {
        dispatch("setNotification", {
          msg: err,
          error: NotificationType.error,
        });
        throw "error";
      }
    },
    async connectWeb3({ dispatch }) {
      try {
        const { message, address, signature } = await web3.connectInjected();
        await dispatch("apiConnect", { address, signature, message });
      } catch (err) {
        dispatch("setNotification", {
          msg: err,
          error: NotificationType.error,
        });
        throw "error";
      }
    },
    async apiConnect({ commit, dispatch }, { address, signature, message }) {
      await api.connect({ signature, address, message });
      commit("setAddress", address);
      commit("setAuthMessage", message);
      dispatch("setNotification", {
        msg: i18n.global.t("notification.connected"),
        error: NotificationType.success,
      });
    },
    async getAssets({ state, commit, dispatch }) {
      if (state.assets.length) return;
      try {
        commit("updateLoader");
        const assets = await api.getAssets({
          address: state.address,
          message: state.authMessage,
        });
        commit("setAssets", assets);
        commit("updateLoader", -1);
      } catch (err) {
        commit("updateLoader", -1);
        dispatch("disconnect");
        dispatch("setNotification", {
          msg: err,
          error: NotificationType.error,
        });
      }
    },
    async getCustomizations({ state, commit, dispatch }, assetId) {
      if (state.customizationsAssetId === assetId) return;
      try {
        commit("updateLoader");
        const customizations = await api.getCustomizations({
          address: state.address,
          message: state.authMessage,
          assetId,
        });
        commit("setCustomizations", { customizations, assetId });
        commit("updateLoader", -1);
      } catch (err) {
        commit("updateLoader", -1);
        dispatch("setNotification", {
          msg: err,
          error: NotificationType.error,
        });
      }
    },
    async getSelectedCustomizations(
      { state, commit, dispatch },
      { assetId, badgerId }
    ) {
      try {
        const customizations = await api.getSelectedCustomizations({
          address: state.address,
          message: state.authMessage,
          assetId,
          badgerId,
        });
        commit("setInitialSelectedCustomizations", {
          customizations,
          assetId,
          badgerId,
        });
        if (!state.edit.customizations.background)
          commit("setDefaultBackground");
      } catch (err) {
        dispatch("setNotification", {
          msg: err,
          error: NotificationType.error,
        });
      }
    },
    async saveCustomizations(
      { state, commit, dispatch },
      { assetId, assetIndex, badgerLink }
    ) {
      try {
        let ids: number[] = [];
        commit("updateEditLoading");
        Object.keys(state.edit.customizations).forEach(function (key) {
          ids.push(
            state.edit.customizations[key]?.assetCustomizationId as number
          );
        });
        ids = ids = ids.filter((i) => !!i);

        await api.saveCustomizations({
          address: state.address,
          message: state.authMessage,
          assetIndex,
          assetId,
          customizations: ids,
        });

        commit("updateEditLoading");
        commit("setEditing", false);
        commit("updateBadgerLink", { assetId, assetIndex, badgerLink });
        dispatch("setNotification", {
          msg: i18n.global.t("notification.saved"),
          error: NotificationType.success,
        });
      } catch (err) {
        dispatch("setNotification", {
          msg: err,
          error: NotificationType.error,
        });
      }
    },
    disconnect({ state, commit, dispatch }) {
      if (!state.address) return;
      commit("setAddress", null);
      dispatch("setNotification", {
        msg: i18n.global.t("notification.disconnected"),
        error: NotificationType.success,
      });
    },
    setCustomization({ commit }, item) {
      commit("setEditing", true);
      commit("setCustomization", item);
    },
    cancelChanges({ commit }, customizations) {
      commit("setEditing", false);
      commit("resetCustomizations", customizations);
    },
    setNotification({ commit }, notification) {
      commit("setNotification", notification);
      setTimeout(() => {
        commit("setNotification", {
          msg: null,
          error: NotificationType.empty,
        });
      }, 3100);
    },
    closeNotification({ commit }) {
      commit("setNotification", {
        msg: null,
        error: NotificationType.empty,
      });
    },
    closeLoader({ state, commit }) {
      commit("updateLoader", state.loading * -1);
    },
  },
  plugins: [persistent.address, persistent.message],
});

const parseToken = (token: string) => {
  const signature = token.slice(0, 132);
  const address = token.slice(132, 174);
  const message = token.slice(174);
  return { signature, address, message };
};

const createLink = ({
  background,
  hat,
  pants,
  shirt,
  accessory,
}: {
  [key: string]: Customization | null;
}): string => {
  let combination = "";
  combination += background
    ? (background.assetCustomizationId || background.id) + "_"
    : "0_";
  combination += shirt ? shirt.assetCustomizationId + "_" : "0_";
  combination += pants ? pants.assetCustomizationId + "_" : "0_";
  combination += hat ? hat.assetCustomizationId + "_" : "0_";
  combination += accessory ? accessory.assetCustomizationId : "0";
  return combination;
};
