import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "landing",
    component: () => import("@/views/LandingPage.vue"),
  },
  {
    path: "/:badgerId/edit",
    name: "edit",
    component: () => import("@/views/EditPage.vue"),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/error",
    name: "error-page",
    component: () => import("@/views/ErrorPage.vue"),
    props: true,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "notfound",
    component: () => import("@/views/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0, left: 0, behavior: "smooth" };
  },
});

export default router;
